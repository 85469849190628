// Copyright (C) 2021 Deconve Technology. All rights reserved.

interface Props {
  height: number;
  width: number;
  currentImageInfo: CurrentImageInfo;
  currentImageContainer: CurrentImageContainer;
}

interface CurrentImageInfo {
  width: number;
  height: number;
  top_left_y: number;
  top_left_x: number;
}

interface CurrentImageContainer {
  offsetWidth: number;
  offsetHeight: number;
}

interface BoundingBox {
  width: number;
  height: number;
  top: string;
  left: string;
}

export default function boundingBoxCalc({
  currentImageInfo, currentImageContainer, height, width,
}: Props): BoundingBox | undefined {
  if (width === 0 || height === 0) {
    return undefined;
  }

  // It gets aspect ratio of the original image.
  const aspectRatio = height / width;

  // It gets the proportional percentage to recalculate the sizes when the image is resized.
  const percentageWidth = currentImageInfo.width / width;
  const percentageHeight = currentImageInfo.height / height;

  // It gets position percentage
  const percentageY = currentImageInfo.top_left_y / height;
  const percentageX = currentImageInfo.top_left_x / width;

  // It gets the carousel div size
  let imageWidth = currentImageContainer.offsetWidth;
  let imageHeight = currentImageContainer.offsetHeight;

  // It calculates the size that the image must be
  const imageWidthProportional = imageHeight / aspectRatio;
  const imageHeightProportinal = imageWidth * aspectRatio;

  // It verifies if the proportion is right
  if (imageHeight < imageHeightProportinal) {
    imageWidth = imageWidthProportional;
  } else if (imageWidth < imageWidthProportional) {
    imageHeight = imageHeightProportinal;
  }

  // It gets the spaces that aren't part of the image considering the two sides.
  const imageHeightDifference = currentImageContainer.offsetHeight - imageHeight;
  const imageWidthDifference = currentImageContainer.offsetWidth - imageWidth;

  return {
    width: imageWidth * percentageWidth,
    height: imageHeight * percentageHeight,
    // The difference is divided to get only one side of it and calculate the right position.
    top: `${(imageHeight * percentageY) + (imageHeightDifference / 2)}px`,
    left: `${(imageWidth * percentageX) + (imageWidthDifference / 2)}px`,
  };
}
