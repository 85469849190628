<template>
  <div
    :style="{
      borderColor: $vuetify.theme.currentTheme.border,
      backgroundColor: $vuetify.theme.currentTheme.inputBackground,
      height: $vuetify.breakpoint.mobile? '32px' : '40px'
    }"
    class="input-container"
  >
    <div>
      <v-dialog
        v-if="$vuetify.breakpoint.mobile"
        ref="dateDialog"
        v-model="dateModal"
        :return-value.sync="datePicker"
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateLabel"
            prepend-icon="mdi-calendar"
            readonlys
            v-bind="attrs"
            hide-details
            class="text-field"
            :style="{maxWidth: '132px'}"
            :disabled="disabled"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="datePicker"
          color="primary"
          :locale="$i18n.locale"
          :min="minDate"
          :max="maxDate"
          @input="showDatePicker = false"
        >
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click="$refs.dateDialog.save(datePicker)"
          >
            {{ $t('deconve.ok') }}
          </v-btn>
        </v-date-picker>
      </v-dialog>

      <v-menu
        v-else
        v-model="showDatePicker"
        :close-on-content-click="false"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateLabel"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            hide-details
            class="text-field"
            :style="{maxWidth: '132px'}"
            :disabled="disabled"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="datePicker"
          color="primary"
          :locale="$i18n.locale"
          :min="minDate"
          :max="maxDate"
          @input="showDatePicker = false"
        />
      </v-menu>
    </div>

    <div>
      <v-dialog
        v-if="$vuetify.breakpoint.mobile"
        ref="timeDialog"
        v-model="timeModal"
        :return-value.sync="timePicker"
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="showTimePickerValue"
            append-icon="mdi-clock"
            readonly
            v-bind="attrs"
            hide-details
            class="text-field pl-2"
            :style="{maxWidth: '96px'}"
            :disabled="disabled"
            v-on="on"
          />
        </template>
        <v-time-picker
          v-model="timePicker"
          flat
          format="24hr"
          class="rounded-0"
          color="primary"
          @click:minute="$refs.timeDialog.save(timePicker)"
        >
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click="$refs.timeDialog.save(timePicker)"
          >
            {{ $t('deconve.ok') }}
          </v-btn>
        </v-time-picker>
      </v-dialog>

      <v-menu
        v-else
        ref="timePickerMenu"
        v-model="showTimePicker"
        :close-on-content-click="false"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="showTimePickerValue"
            append-icon="mdi-clock"
            readonly
            v-bind="attrs"
            hide-details
            class="text-field pl-2"
            :style="{maxWidth: '96px'}"
            :disabled="disabled"
            v-on="on"
          />
        </template>
        <v-time-picker
          v-model="timePicker"
          flat
          format="24hr"
          class="rounded-0"
          color="primary"
          @click:minute="$refs.timePickerMenu.save(timePicker)"
        />
      </v-menu>
    </div>
  </div>
</template>

<script>
// Copyright (C) 2023 Deconve Technology. All rights reserved.

export default {
  name: 'DateTimeFieldPicker',
  props: {
    placeholder: { type: String, default: '' },
    value: { type: String, default: '' },
    max: { type: String, default: undefined },
    min: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
  },
  data: () => ({
    showDatePicker: false,
    showTimePicker: false,
    selectedTime: undefined,
    selectedDate: undefined,
    dateModal: false,
    timeModal: false,
  }),
  computed: {
    showTimePickerValue() {
      return this.value ? this.timePicker : '';
    },
    datePicker: {
      get() {
        if (this.selectedDate) return this.selectedDate;

        return this.dateToIsoFormat(this.value ? new Date(this.value) : new Date());
      },
      set(newDate) {
        this.selectedDate = newDate;
        this.setDateTime();
      },
    },
    timePicker: {
      get() {
        if (this.selectedTime) return this.selectedTime;

        return this.getTime(this.value ? new Date(this.value) : new Date());
      },
      set(newTime) {
        this.selectedTime = newTime;
        this.setDateTime();
      },
    },
    dateLabel() {
      if (this.value) {
        const date = this.$moment(this.value);

        if (date.isValid()) {
          return date.format('DD/MM/YYYY');
        }
      }

      return '';
    },
    minDate() {
      return this.min ? this.dateToIsoFormat(new Date(this.min)) : undefined;
    },
    maxDate() {
      return this.dateToIsoFormat(this.max ? new Date(this.max) : new Date());
    },
  },
  methods: {
    dateToIsoFormat(date) {
      // v-date-picker expects date in YYYY-MM-DD format. Because of the user locale, we can not
      // 'just use' ISOFormat
      let month = `${date.getMonth() + 1}`;
      let day = `${date.getDate()}`;
      const year = date.getFullYear();

      if (month.length < 2) { month = `0${month}`; }

      if (day.length < 2) { day = `0${day}`; }

      return [year, month, day].join('-');
    },
    getTime(date) {
      let hour = `${date.getHours()}`;
      let minute = `${date.getMinutes()}`;

      if (hour.length < 2) { hour = `0${hour}`; }

      if (minute.length < 2) { minute = `0${minute}`; }

      return [hour, minute].join(':');
    },
    setDateTime() {
      const localDateStr = `${this.datePicker}T${this.timePicker}`;
      const dateIsoFormat = new Date(localDateStr).toISOString();

      this.$emit('input', dateIsoFormat);
    },
  },
};
</script>

<style scoped>
  .input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    border: 1px solid black;
  }

  .text-field {
    padding: 0;
    margin: 0;
    align-items: center;
  }

  .text-field >>> .v-input__slot::before {
    border-style: none !important;
  }
</style>
