<template>
  <v-container
    class="pa-0"
    style="overflow-y: auto; height: 100%; width: 100%"
    fluid
  >
    <v-data-iterator
      class="d-flex flex-column"
      style="height: 100%"
      :items="people"
      :server-items-length="people.length"
      :loading="isLoading"
      hide-default-footer
      @update:page="onPageNumberUpdated"
    >
      <template v-slot:header>
        <div
          class="pa-3 pb-4 d-flex justify-space-between align-center"
          style="border-bottom: 1px solid;"
          :style="{
            borderColor: $vuetify.theme.themes.light.border,
          }"
        >
          <div
            class="d-flex align-center"
            style="flex: 1"
          >
            <v-menu
              offset-y
              nudge-bottom="12"
              min-width="480px"
            >
              <template v-slot:activator="{attrs}">
                <input-text
                  v-model="filterOptions.search"
                  v-bind="attrs"
                  max-width="440px"
                  right-icon="mdi-camera-outline"
                  left-icon="mdi-magnify"
                  :placeholder="$t('deconve.search')"
                  @rightIconClicked="$emit('onSearchByImageButtonClicked')"
                />
              </template>
            </v-menu>
            <v-menu
              :close-on-content-click="false"
              offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :color="hasFilterChanged? 'primary' : 'neutral'"
                  :class="hasFilterChanged? 'primaryHighlight' : 'white'"
                  :small="$vuetify.breakpoint.mobile"
                  v-bind="attrs"
                  class="mx-2"
                  v-on="on"
                >
                  <v-icon>
                    {{ hasFilterChanged? 'mdi-filter':'mdi-filter-outline' }}
                  </v-icon>
                </v-btn>
              </template>
              <people-filter
                v-model="filterOptions"
                :has-changed="hasFilterChanged"
                @change="onFilterChanged"
                @clear="clearFilters"
              />
            </v-menu>
          </div>
          <v-btn
            color="primary"
            elevation="0"
            :disabled="!$can('create', 'com.deconve.faceid.person')"
            :small="$vuetify.breakpoint.mobile"
            @click="$router.push({ name: 'faceid-person-register' })"
          >
            <v-icon :left="!$vuetify.breakpoint.mobile">
              {{ icons.addPerson }}
            </v-icon>

            <div v-if="!$vuetify.breakpoint.mobile">
              {{ $t('deconve.person.registerPerson') }}
            </div>
          </v-btn>
        </div>
      </template>

      <template v-slot:loading>
        <span
          class="ml-1 pa-2"
        >
          {{ $t('deconve.loading') }}
        </span>
      </template>

      <template v-slot:no-data>
        <span
          class="ml-1 pa-2 neutralPrimary--text text-subtitle-2 text-sm-subtitle-1 font-weight-bold"
        >
          {{ $t('deconve.noPerson') }}
        </span>
      </template>

      <template v-slot:default="props">
        <div
          style="flex: 1; overflow-y: auto;"
          :style="{
            backgroundColor: $vuetify.theme.themes.light.background,
          }"
        >
          <v-progress-linear
            v-if="isLoading"
            :indeterminate="true"
          />

          <div class="pa-2">
            <div
              class="d-flex flex-wrap"
            >
              <v-col
                v-for="item in props.items"
                :key="item.id"
                class="pa-1"
                cols="12"
                sm="6"
                md="4"
                lg="3"
                :data-cy="item.id"
              >
                <person-preview
                  :id="item.id"
                />
              </v-col>
            </div>
          </div>
        </div>
      </template>

      <template
        v-slot:footer
      >
        <footer-data-iterator
          :items-per-page-array="itemsPerPageArray"
          :items-per-page="itemsPerPage"
          :page="page"
          :total-data="numberOfPeople"
          @changeItensPerPage="updateItemsPerPage"
          @changePage="onPageNumberUpdated"
        />
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { mdiPlus } from '@mdi/js';

import InputText from '@/components/InputText.vue';
import FooterDataIterator from '@/components/FooterDataIterator.vue';

import {
  booleanToPersonFileStatus,
  booleanToPersonVideoStatus,
  defaultPeopleFilterOptions,
} from '@/utils/faceidFilters';

import PeopleFilter from './PeopleFilter.vue';
import PersonPreview from './PersonPreview.vue';

export default {
  name: 'PeopleDataIterator',
  components: {
    PersonPreview,
    PeopleFilter,
    InputText,
    FooterDataIterator,
  },
  data() {
    return {
      itemsPerPageArray: [4, 8, 12],
      isLoading: false,
      page: 1,
      itemsPerPage: 8,
      filterOptions: defaultPeopleFilterOptions(),
      icons: {
        addPerson: mdiPlus,
      },
    };
  },
  computed: {
    ...mapGetters({
      personList: 'faceid/personList',
      dataIteratorSettings: 'faceid/getPersonIteratorSettings',
    }),
    numberOfPeople() {
      return this.personList.total;
    },
    people() {
      return this.personList.items;
    },
    hasFilterChanged() {
      const defaultOption = defaultPeopleFilterOptions();

      return Boolean(
        this.filterOptions.sortBy !== defaultOption.sortBy
        || this.filterOptions.sortOrder !== defaultOption.sortOrder
        || this.filterOptions.status !== defaultOption.status
        || this.filterOptions.fileStatus !== defaultOption.fileStatus
        || this.filterOptions.videoStatus !== defaultOption.videoStatus
        || this.filterOptions.createdAfter !== defaultOption.createdAfter
        || this.filterOptions.createdBefore !== defaultOption.createdBefore
        || this.filterOptions.createdDateOption !== defaultOption.createdDateOption
        || this.filterOptions.sharingStatus !== defaultOption.sharingStatus
        || this.filterOptions.tags.length > 0
        || this.filterOptions.noTags !== defaultOption.noTags
        || this.filterOptions.unitId !== defaultOption.unitId
        || this.filterOptions.unitName !== defaultOption.unitName
        || this.filterOptions.noUnit !== defaultOption.noUnit
        || this.filterOptions.hasFalseNegative !== defaultOption.hasFalseNegative
        || this.filterOptions.search !== defaultOption.search
        || this.filterOptions.workspaceId !== defaultOption.workspaceId
        || this.filterOptions.workspaceName !== defaultOption.workspaceName,
      );
    },
    skip() {
      return (this.page - 1) * this.itemsPerPage;
    },
    search() {
      return this.filterOptions.search;
    },
  },
  watch: {
    numberOfPages() {
      if (this.page > this.numberOfPages) {
        this.onPageNumberUpdated(Math.max(this.numberOfPages, 1));
      }
    },
    search() {
      this.onFilterChanged();
    },
  },
  created() {
    this.getDataIteratorInfo();
    this.onFilterChanged();
  },
  methods: {
    ...mapActions({
      fetchPeople: 'faceid/fetchPeople',
      savePersonIteratorSettings: 'faceid/setPersonIteratorSettings',
      removePersonFinderByImage: 'faceid/removePersonFinderByImage',
      setPersonFinderPagination: 'faceid/setPersonFinderPagination',
    }),
    clearFilters() {
      this.filterOptions = defaultPeopleFilterOptions();

      this.page = 1;

      this.removePersonFinderByImage();
      this.onFilterChanged();
    },
    getDataIteratorInfo() {
      if (this.dataIteratorSettings) {
        const {
          page,
          search,
          sortOrder,
          itemsPerPage,
          sortBy,
          createdAfter,
          createdBefore,
          createdDateOption,
          status,
          hasFiles,
          hasVideos,
          tags,
          noTags,
          sharingStatus,
          unitId,
          unitName,
          noUnit,
          hasFalseNegative,
          workspaceId,
          workspaceName,
        } = this.dataIteratorSettings;

        // Data-iterator updates the page value if it is greater than the number of pages availables
        if (page) {
          this.filterOptions = {
            sortOrder,
            sortBy,
            tags,
            noTags,
            sharingStatus,
            status,
            fileStatus: booleanToPersonFileStatus(hasFiles),
            videoStatus: booleanToPersonVideoStatus(hasVideos),
            unitId,
            unitName,
            noUnit,
            hasFalseNegative,
            createdAfter,
            createdBefore,
            createdDateOption,
            search,
            workspaceId,
            workspaceName,
          };

          this.itemsPerPage = itemsPerPage;
          this.page = page;
        }
      }
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
      this.page = 1;
      this.definePaginationType();
    },
    definePaginationType() {
      this.onFilterChanged();
    },
    onPageNumberUpdated(page) {
      this.page = page;
      this.definePaginationType();
    },
    onPersonSearchImage() {
      this.setPersonFinderPagination({ page: this.page, itemsPerPage: this.itemsPerPage });
    },
    saveIteratorSettings() {
      const filterOptions = {
        ...this.filterOptions, page: this.page, itemsPerPage: this.itemsPerPage,
      };

      this.savePersonIteratorSettings(filterOptions);
    },
    handleFetchPeople() {
      this.isLoading = true;

      this.fetchPeople().then(() => {
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
    onFilterChanged() {
      this.saveIteratorSettings();
      this.handleFetchPeople();
    },
  },
};
</script>
