<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    @keydown.esc="cancel"
  >
    <v-card
      class="px-6 py-8"
    >
      <v-toolbar
        dark
        :color="options.color"
        dense
        flat
        class="align-center justify-space-between"
      >
        <v-toolbar-title class="h6 text-sm-h5 font-weight-bold textBlack--text">
          {{ $t('deconve.selectUnit') }}
        </v-toolbar-title>
        <v-spacer />
        <square-button
          v-if="$vuetify.breakpoint.mobile"
          menu-disabled
          icon-name="mdi-close"
          outlined
          color="transparentBackground"
          style="margin-top: auto"
          icon-color="neutral"
          @clicked="cancel"
        />
      </v-toolbar>
      <v-card-text
        class="pa-4"
      >
        <input-text
          v-model="search"
          left-icon="mdi-magnify"
          :placeholder="$t('deconve.search')"
          @focused="isListActive = true"
        />
        <div
          class="mb-2 mt-6"
          style="max-height: 280px; overflow-y: auto"
        >
          <v-treeview
            :search="search"
            :items="units"
            item-text="name"
            activatable
            hoverable
            selected-color="primary"
            :active-class="
              selectedUnitIds.length > 0 ? 'v-treeview-node--active' : 'textBlack--text'
            "
            :active="selectedUnitIds"
            @update:active="onUnitSelected"
          >
            <template v-slot:label="{ item }">
              {{ item.name }}
            </template>
          </v-treeview>
        </div>
      </v-card-text>
      <v-card-text>
        <div
          v-if="selectedUnitName"
          class="subtitle-2 text-sm-subtitle-1 font-weight-medium neutral--text"
        >
          {{ $t('deconve.selectedUnit') }}
        </div>
        <div
          v-if="selectedUnitName"
          class="d-flex flex-wrap"
        >
          <v-chip
            close
            close-icon="mdi-close"
            label
            outlined
            @click:close="removeSelectedUnit"
          >
            {{ selectedUnitName }}
          </v-chip>
        </div>
      </v-card-text>
      <v-card-actions
        class="pt-2"
      >
        <v-spacer />
        <v-btn
          v-if="!$vuetify.breakpoint.mobile"
          outlined
          :height="$vuetify.breakpoint.mobile? '36px' : '40px'"
          elevation="0"
          class="d-flex custom-transform-class text-none px-3"
          color="neutral"
          @click="cancel"
        >
          {{ $t('deconve.cancel') }}
        </v-btn>
        <v-btn
          :height="$vuetify.breakpoint.mobile? '36px' : '40px'"
          elevation="0"
          class="d-flex custom-transform-class text-none px-3"
          color="primary"
          @click="confirm"
        >
          {{ $t('deconve.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import SquareButton from './SquareButton.vue';
import InputText from './InputText.vue';

export default {
  name: 'UnitSelectionDialog',
  components: {
    SquareButton,
    InputText,
  },
  props: {
    workspaceId: { type: String, default: undefined },
    isOnDeconveHub: { type: Boolean, default: false },
  },
  data: () => ({
    search: '',
    isListActive: true,
    dialog: false,
    resolve: null,
    reject: null,
    selectedUnitIds: [],
    selectedUnit: null,
    options: {
      color: 'white',
      width: 480,
    },
  }),
  computed: {
    ...mapGetters({
      units: 'units/getUnitsSortedByName',
    }),
    selectedUnitName() {
      return this.selectedUnit?.name;
    },
  },
  watch: {
    units() {
      if (this.selectedUnitIds.length > 0) {
        this.onUnitSelected([this.selectedUnitIds[0]]);
      }
    },
    workspaceId() {
      if (this.isOnDeconveHub && this.workspaceId) {
        this.fetchUnits(this.workspaceId);
      }
    },
  },
  created() {
    this.fetchUnits();
  },
  methods: {
    ...mapActions({
      fetchUnits: 'units/fetchUnits',
    }),
    open(unitId) {
      if (unitId) {
        this.onUnitSelected([unitId]);
      }

      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    onUnitSelected([unitId]) {
      if (unitId) {
        this.selectedUnitIds = [unitId];

        if (this.units) {
          this.selectedUnit = this.units.find(({ id }) => (id === unitId));
        } else {
          this.selectedUnit = null;
        }
      } else {
        this.selectedUnit = null;
      }
    },
    confirm() {
      this.resolve(this.selectedUnit);
      this.reset();
      this.dialog = false;
    },
    reset() {
      this.selectedUnit = null;
      this.selectedUnitIds = [];
    },
    cancel() {
      this.dialog = false;
      this.reset();
      this.reject();
    },
    removeSelectedUnit() {
      this.selectedUnitIds.splice(0, 1);
    },
  },
};
</script>
