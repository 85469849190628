<template>
  <div
    :style="{ 'max-width': anyPeriod && '330px'}"
    class="ma-0 pa-0 justify-center"
  >
    <!-- The v-if is not working inside the template activator -->
    <v-responsive
      v-if="!hideDetails && !$vuetify.breakpoint.mobile"
      class="ma-0 pa-0"
      :width="`${dateFieldWidth}px`"
    >
      <v-text-field
        id="menu-activator"
        ref="dateLabelField"
        v-model="dateLabel"
        append-icon="mdi-calendar"
        readonly
        dense
        outlined
        hide-details
        :disabled="disabled"
      />
    </v-responsive>

    <v-btn
      v-else
      id="mobile-menu-activator"
      icon
      small
      :disabled="disabled"
    >
      <v-icon>
        {{ icons.calendar }}
      </v-icon>
    </v-btn>

    <v-menu
      v-model="show"
      :activator="menuActivator"
      :close-on-content-click="false"
      offset-y
      max-width="300px"
    >
      <v-card>
        <v-row style="margin: 0px">
          <v-radio-group
            v-model="dateOption"
            style="margin-top: 10px"
          >
            <v-col
              cols="auto"
              style="padding-left: 14px"
            >
              <v-radio
                v-for="(item, index) in items"
                :key="index"
                :label="$t(item.label)"
                :value="item.value"
              />
            </v-col>
          </v-radio-group>
        </v-row>
        <p style="margin-left: 15px; margin-bottom: 5px">
          {{ $t('deconve.from') }}
        </p>
        <date-time-field-picker
          v-model="startDatePicker"
          :disabled="!isCustom"
          :max="endDatePicker"
          class="mx-4"
        />

        <p style="margin-left: 15px; margin-top: 11px; margin-bottom: 5px">
          {{ $t('deconve.until') }}
        </p>

        <date-time-field-picker
          v-model="endDatePicker"
          :min="startDatePicker"
          :disabled="!isCustom"
          class="mx-4"
        />

        <v-card-actions class="mt-4">
          <v-spacer />
          <v-btn
            depressed
            outlined
            color="neutral"
            @click="cancel"
          >
            {{ $t('deconve.cancel') }}
          </v-btn>
          <v-btn
            depressed
            color="primary"
            @click="setDateTime"
          >
            {{ $t('deconve.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { mdiCalendar } from '@mdi/js';
import { DATE_TIME_OPTIONS, getDateAndTimeRange } from '@/utils/dateTimeRange';

import DateTimeFieldPicker from '@/components/DateTimeFieldPicker.vue';

export default {
  name: 'DateTimeRangePicker',
  components: {
    DateTimeFieldPicker,
  },
  props: {
    placeholder: { type: String, default: '' },
    endDateTime: { type: String, required: true },
    startDateTime: { type: String, required: true },
    mode: { type: String, required: true },
    hideDetails: { type: Boolean, required: false, default: false },
    anyPeriod: { type: Boolean, required: false },
    disabled: { type: Boolean, default: false },
  },
  data: () => ({
    show: false,
    isCustom: false,
    items: [
      { label: 'deconve.today', value: DATE_TIME_OPTIONS.today },
      { label: 'deconve.yesterday', value: DATE_TIME_OPTIONS.yesterday },
      { label: 'deconve.lastTwoDays', value: DATE_TIME_OPTIONS.lastTwoDays },
      { label: 'deconve.lastSevenDays', value: DATE_TIME_OPTIONS.lastSevenDays },
      { label: 'deconve.lastFifteenDays', value: DATE_TIME_OPTIONS.lastFifteenDays },
      { label: 'deconve.lastThirtyDays', value: DATE_TIME_OPTIONS.lastThirtyDays },
      { label: 'deconve.thisMonth', value: DATE_TIME_OPTIONS.thisMonth },
      { label: 'deconve.custom', value: DATE_TIME_OPTIONS.custom },
    ],
    dateOption: DATE_TIME_OPTIONS.lastTwoDays,
    startDate: undefined,
    endDate: undefined,
    dateFieldWidth: 100,
    icons: {
      calendar: mdiCalendar,
    },
  }),

  computed: {
    menuActivator() {
      if (this.hideDetails || this.$vuetify.breakpoint.mobile) return '#mobile-menu-activator';

      return '#menu-activator';
    },
    startDatePicker: {
      get() {
        return this.startDate || this.startDateTime;
      },
      set(newDate) {
        this.startDate = newDate;
      },
    },
    endDatePicker: {
      get() {
        return this.endDate || this.endDateTime;
      },
      set(newDate) {
        this.endDate = newDate;
      },
    },
    dateLabel() {
      let label;

      if (this.mode === DATE_TIME_OPTIONS.custom) {
        const datePattern = 'DD/MM/YYYY HH:mm';

        const startDate = this.$moment(this.startDateTime).format(datePattern);
        const endDate = this.$moment(this.endDateTime).format(datePattern);

        label = `${startDate} - ${endDate}`;
      } else {
        label = this.$t(`deconve.${this.mode}`);
      }

      this.updateDateFieldWidth(label);
      return label;
    },
  },
  watch: {
    dateOption(newValue) {
      this.isCustom = newValue === DATE_TIME_OPTIONS.custom;
    },
    mode(newValue) {
      this.dateOption = newValue;
    },
  },
  mounted() {
    // To adjust the date field width initially
    this.updateDateFieldWidth(this.dateLabel);
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  created() {
    this.dateOption = this.mode;

    if (this.anyPeriod) {
      this.items.unshift({ label: 'deconve.anyPeriod', value: DATE_TIME_OPTIONS.anyPeriod });
    }
  },
  methods: {
    resetDateAndTime() {
      this.startDate = undefined;
      this.endDate = undefined;
      this.dateOption = this.mode;
    },
    setDateTime() {
      if (this.isCustom) {
        const startDate = this.startDatePicker;
        const endDate = this.endDatePicker;

        this.$emit('change', { startDate, endDate, mode: this.dateOption });
        this.close();
        this.resetDateAndTime();
      } else if (this.dateOption) {
        const { startDate, endDate } = getDateAndTimeRange(this.dateOption);

        this.$emit('change', { startDate, endDate, mode: this.dateOption });
        this.close();
      }
    },
    cancel() {
      this.resetDateAndTime();
      this.close();
    },
    close() {
      this.show = false;
    },
    getTextWidth(text) {
      // Create a hidden div element to measure the text width
      const { dateLabelField } = this.$refs;

      if (!dateLabelField) return -1;

      const hiddenDiv = document.createElement('div');
      const style = getComputedStyle(dateLabelField.$el);

      // Set the styles of the hidden div to match the text field
      hiddenDiv.style.position = 'absolute';
      hiddenDiv.style.visibility = 'hidden';
      hiddenDiv.style.whiteSpace = 'nowrap';
      hiddenDiv.style.font = style.font;
      hiddenDiv.style.fontSize = style['font-size'];
      hiddenDiv.style.fontWeight = style['font-weight'];
      hiddenDiv.innerText = text;

      // Append the hidden div to the DOM
      document.body.appendChild(hiddenDiv);

      // Set the width of the text field based on the hidden div's width plus some padding
      const width = hiddenDiv.offsetWidth;

      // Remove the hidden div from the DOM
      document.body.removeChild(hiddenDiv);

      return width;
    },
    updateDateFieldWidth(dateLabel) {
      this.dateFieldWidth = this.getTextWidth(dateLabel) + 80; // Add padding
    },
    handleResize() {
      // To adjust the date field width initially
      this.updateDateFieldWidth(this.dateLabel);
    },
  },
};
</script>
<style>
.v-input__control div[role="radiogroup"] {
  grid-template-columns: auto !important;
}

.v-input--radio-group__input {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
  gap: 10px !important;
}
input[role="button"][id="input-59"] {
  margin-left: 14px !important;
}
.v-input__append-inner {
  margin-right: 10px !important;
}

.v-text-field fieldset {
  border-color: rgb(223, 227, 232) !important;
  background-color: rgb(250, 250, 250) !important;
}

.v-text-field--outlined.v-input--is-focused fieldset {
  border: 1px solid currentColor !important;
}

</style>
