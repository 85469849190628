var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative","width":"100%","height":"100%"}},[_c('span',{style:({
      position: 'absolute',
      top: '0',
      left: '0',
      cursor: _vm.defineCursor,
      width: '100%',
      height: '100%',
      backgroundColor: 'transparent',
      zIndex: _vm.hasZoom && !_vm.showZoom ? '1' : '0'}),on:{"click":_vm.handleClick,"mousedown":_vm.handleMouseDown,"mousemove":_vm.handleMouseMove,"mouseup":_vm.handleMouseUp}}),(!_vm.showZoom)?_vm._t("default"):_c('div',{staticClass:"zoom-box d-flex align-center",style:({ cursor: _vm.defineCursor }),on:{"click":_vm.handleClick,"mousedown":_vm.handleMouseDown,"mousemove":_vm.handleMouseMove,"mouseup":_vm.handleMouseUp}},[_c('v-img',{style:(_vm.zoomBoxStyle),attrs:{"src":_vm.src,"eager":"","aspect-ratio":"1","height":"100%","contain":""}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }