<template>
  <v-container
    class="pa-0"
    style="overflow-y: auto; height: 100%; width: 100%"
    fluid
  >
    <people-finder-data-iterator
      v-if="isToShowPersonFinder"
      @close="isToShowPersonFinder = false"
    />
    <people-data-iterator
      v-else
      @onSearchByImageButtonClicked="isToShowPersonFinder = true"
    />
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex';

import PeopleDataIterator from './PeopleDataIterator.vue';
import PeopleFinderDataIterator from './PeopleFinderDataIterator.vue';

export default {
  name: 'People',
  components: {
    PeopleDataIterator,
    PeopleFinderDataIterator,
  },
  data() {
    return {
      isToShowPersonFinder: false,
    };
  },
  computed: {
    ...mapGetters({
      personFinderImage: 'faceid/personFinderImage',
    }),
  },
  created() {
    if (this.personFinderImage) this.isToShowPersonFinder = true;
  },
};
</script>
