var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-center align-center flex-wrap mt-auto",staticStyle:{"height":"64px","border-top":"1px solid","margin-bottom":"1px"},style:({
    borderColor: _vm.$vuetify.theme.themes.light.border,
  })},[_c('div',{staticClass:"pa-2"},[_c('span',{staticClass:"grey--text input-text body-2 text-sm-body-1 neutral--text"},[_vm._v(" "+_vm._s(_vm.$t( 'deconve.pageIteratorPeople', [ _vm.numberOfTheFirstItemInPage, _vm.numberOfItemsInPage(_vm.totalData > 0 ? _vm.totalData : 0), _vm.totalData > 0 ? _vm.totalData : 0 ] ))+" ")])]),_c('div',{staticClass:"pa-2"},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.formerPage}},[_c('v-icon',{attrs:{"size":"22","color":"neutral"}},[_vm._v(" "+_vm._s(_vm.icons.mdiChevronLeft)+" ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.nextPage}},[_c('v-icon',{attrs:{"size":"22","color":"neutral"}},[_vm._v(" "+_vm._s(_vm.icons.mdiChevronRight)+" ")])],1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","elevation":"0","small":_vm.$vuetify.breakpoint.mobile}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"neutral"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"neutral--text body-2 text-sm-body-1"},[_vm._v(" "+_vm._s(_vm.$t('$vuetify.dataFooter.itemsPerPageText'))+" ")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 body-2 text-sm-body-1",attrs:{"dark":"","text":"","color":"neutral","small":_vm.$vuetify.breakpoint.mobile}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.itemsPerPage)+" "),_c('v-icon',{attrs:{"small":_vm.$vuetify.breakpoint.mobile}},[_vm._v(" "+_vm._s(_vm.icons.mdiChevronDown)+" ")])],1)]}}])},[_c('v-list',_vm._l((_vm.itemsPerPageArray),function(number,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.$emit('changeItensPerPage', number)}}},[_c('v-list-item-title',{staticClass:"input-text body-2 text-sm-body-1 textBlack--text"},[_vm._v(" "+_vm._s(number)+" ")])],1)}),1)],1)],1),_c('v-list-item',[_c('v-list-item-title',{staticClass:"neutral--text body-2 text-sm-body-1"},[_vm._v(" "+_vm._s(_vm.$t('deconve.pages'))+" ")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 body-2 text-sm-body-1",attrs:{"dark":"","text":"","color":"neutral","small":_vm.$vuetify.breakpoint.mobile}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('deconve.pageIterator', [_vm.totalData > 0 ? _vm.page : 0, _vm.numberOfPages]))+" "),_c('v-icon',{attrs:{"small":_vm.$vuetify.breakpoint.mobile}},[_vm._v(" "+_vm._s(_vm.icons.mdiChevronDown)+" ")])],1)]}}])},[_c('v-list',{staticStyle:{"max-height":"300px","overflow-y":"auto"}},_vm._l((_vm.listOfPages),function(number,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.$emit('changePage', number)}}},[_c('v-list-item-title',{staticClass:"input-text body-2 text-sm-body-1 textBlack--text"},[_vm._v(" "+_vm._s(number)+" ")])],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }