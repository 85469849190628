<template>
  <v-card
    width="100%"
    outlined
    :loading="isInLoading"
  >
    <div
      class="pa-4"
      style="max-height: 750px;"
    >
      <v-card-text class="pa-0">
        <div
          v-if="personFinderImage && !isEdit"
          style="position: relative"
        >
          <v-img
            id="personFinderImage"
            v-resize="handleResize"
            :src="personFinderImage"
            class="transparentBackground imageBoundingBox"
            :height="$vuetify.breakpoint.mobile? '150px':'350px'"
            contain
            @load="onImageLoaded"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="primary lighten-1"
                />
              </v-row>
            </template>
            <template v-if="boundingBoxStyles.length > 0">
              <v-tooltip
                v-for="(style, idx) in boundingBoxStyles"
                :key="idx"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    :key="idx"
                    v-bind="attrs"
                    :style="{
                      top: style.top,
                      left: style.left,
                      height: `${style.height}px`,
                      width: `${style.width}px`,
                      'pointer-events': isInLoading ? 'none' : 'auto',
                      border: personFinderSelectedFaceIndex === idx?
                        `1px solid green` : '1px solid red'}"
                    style="position: absolute; cursor: pointer"
                    v-on="on"
                    @click="selectFace(idx)"
                  >
                    <span
                      v-if="personFinderSelectedFaceIndex === idx"
                      class="d-flex justify-center align-center"
                      :style="{
                        position: 'absolute',
                        background: 'green',
                        bottom: '-19px',
                        width: 'max-content',
                        'min-width': '100%',
                        height: '19px',
                        'font-size': `${$vuetify.breakpoint.mobile? '10px' : '14px'}`,
                      }"
                    >
                      {{ personFinderFaces[idx].bounding_box.width }}
                      x
                      {{ personFinderFaces[idx].bounding_box.height }}
                    </span>
                  </div>
                </template>
                <span>{{ $t('deconve.person.searchForThisFace') }}</span>
              </v-tooltip>
            </template>
            <div
              class="d-flex"
              style="position: absolute; top: 8px; left: 8px"
            >
              <div
                v-if="selectedFace"
                class="d-flex"
                style="position: relative; min-height: 35px; min-width: 35px;
                transition: width 5s, height 5s"
              >
                <template>
                  <image-container
                    class="mr-1 transparentBackground elevation-2"
                    rounded
                    :loading="detectFacesLoading"
                    :height="avatarSize"
                    :width="avatarSize"
                    :src="detectFacesLoading ? undefined : selectedFace"
                  />
                </template>
              </div>
            </div>
          </v-img>
        </div>
        <v-sheet
          v-else-if="!isEdit"
          elevation="0"
          class="d-flex justify-center align-center dropbox pa-6"
        >
          <input
            type="file"
            data-cy="input-image"
            accept="image/png, image/jpeg"
            class="input-file"
            required
            @click="clearInputFiles"
            @change="searchPeopleByImage"
          >
          <div style="display: flex; flex-direction: column; align-items: center">
            <v-icon
              :size="$vuetify.breakpoint.mobile? 28 : 32"
              class="mb-4"
              color="info"
            >
              mdi-image-multiple-outline
            </v-icon>
            <span
              style="color: #8F93FF; text-align: center"
              class="d-flex align-center body-2 text-sm-body-1"
            >
              {{ $t('deconve.fileInput') }}
            </span>
          </div>
        </v-sheet>
        <div
          v-else
          style="position: relative"
        >
          <vue-cropper
            ref="cropper"
            :key="cropperIndex"
            :src="personFinderImage"
            class="transparentBackground"
            :container-style="containerStyle"
          />
        </div>
      </v-card-text>
      <v-card-actions
        class="pa-0 pt-4"
        :style="{
          display: 'flex',
          maxWidth: '100%',
          flexDirection: `${$vuetify.breakpoint.mobile ? 'column' : 'row'}`,
          alignItems: `${$vuetify.breakpoint.mobile ? 'center' : 'flex-end'}`,
          justifyContent: 'center',
        }"
      >
        <div
          v-if="personFinderImage && !isEdit && hasFacesInImage"
          :style="{display: 'flex',
                   width: `${$vuetify.breakpoint.mobile ? '100%': '40%'}`,
                   flexDirection: 'column',
          }"
        >
          <span
            class="neutralPrimary--text text-subtitle-2 text-sm-subtitle-1 font-weight-medium"
          >
            {{ $t('deconve.person.similarityLevel') }}
          </span>
          <div
            style="max-height: 30px"
          >
            <v-slider
              v-model="scoreThreshold"
              :min="minScoreThreshold"
              :max="maxScoreThreshold"
              :step="scoreIncrementStep"
              track-color="gray"
              :append-icon="icons.mdiPlus"
              :prepend-icon="icons.mdiMinus"
              :disabled="isLoading"
              @click:append="scoreThreshold += scoreIncrementStep"
              @click:prepend="scoreThreshold -= scoreIncrementStep"
            />
          </div>
          <span
            v-if="isToShowScoreLowMessage"
            style="text-align: center;"
            class="neutralPrimary--text text-subtitle-2 text-sm-subtitle-2"
          >
            {{ $t('deconve.lowerValueForIdentification') }}
          </span>
        </div>

        <div
          v-else-if="hasFacesInImage == false"
          :style="{display: 'flex',
                   width: `${$vuetify.breakpoint.mobile ? '100%': '40%'}`,
                   flexDirection: 'row',
          }"
        >
          <v-icon
            color="neutralPrimary"
            :small="$vuetify.breakpoint.mobile"
            class="mr-4"
          >
            {{ icons.mdiAlert }}
          </v-icon>
          <span
            class="neutralPrimary--text font-weight-bold"
            :style="{ fontSize: `${$vuetify.breakpoint.mobile ? '11px' : '16px'}` }"
          >
            {{ $t('deconve.person.noFacesDetectedInImage') }}
          </span>
        </div>

        <div
          class="mt-4"
          :style="{
            width: `${$vuetify.breakpoint.mobile || isEdit ? '100%': '60%'}`,
            display: 'flex',
            justifyContent: 'flex-end',
          }"
        >
          <rectangle-button
            v-if="isEdit"
            outlined
            icon="mdi-reload"
            color="info"
            class="mr-2"
            @clicked="$refs.cropper.reset()"
          >
            <div class="d-none d-sm-none d-xs-none d-md-flex">
              {{ $t('deconve.reset') }}
            </div>
          </rectangle-button>
          <rectangle-button
            v-if="isEdit"
            outlined
            icon="mdi-close"
            color="warn"
            class="mr-2"
            @clicked="isEdit=false"
          >
            <div class="d-none d-sm-none d-xs-none d-md-flex">
              {{ $t('deconve.cancel') }}
            </div>
          </rectangle-button>
          <rectangle-button
            v-if="personFinderImage"
            outlined
            color="neutral"
            icon="mdi-crop"
            :disabled="isLoading"
            class="mr-2"
            @clicked="cropImage"
          >
            <div class="d-none d-sm-none d-xs-none d-md-flex">
              {{ $t('deconve.crop') }}
            </div>
          </rectangle-button>
          <rectangle-button
            v-if="personFinderImage && !isEdit"
            color="warn"
            data-cy="personfinder-button-remove"
            icon="mdi-close"
            outlined
            :disabled="isLoading"
            class="mr-2"
            @clicked="removePersonFinderByImage"
          >
            <div class="d-none d-sm-none d-xs-none d-md-flex">
              {{ $t('deconve.remove') }}
            </div>
          </rectangle-button>
          <div
            v-if="personFinderImage && !isEdit"
            style="position: relative; overflow: hidden"
          >
            <input
              type="file"
              data-cy="input-image"
              accept="image/png, image/jpeg"
              style="
              position: absolute;
              top: -30px;
              height: 190%;
              width: 100%;
              z-index: 2;
              opacity: 0;
              cursor: pointer
            "
              :disabled="isLoading"
              @click="clearInputFiles"
              @change="searchPeopleByImage"
            >
            <rectangle-button
              color="primary"
              data-cy="personfinder-button-choose-image"
              icon="mdi-image-multiple-outline"
              :disabled="isLoading"
            >
              <div class="d-none d-sm-none d-xs-none d-md-flex">
                {{ $t('deconve.person.chooseImage') }}
              </div>
            </rectangle-button>
          </div>
        </div>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

import RectangleButton from '@/components/RectangleButton.vue';
import ImageContainer from '@/components/ImageContainer.vue';
import { mapActions, mapGetters } from 'vuex';
import boundingBoxCalc from '@/utils/boundingBoxCalc';
import { fileToBase64 } from '@/utils/data';
import VueCropper from 'vue-cropperjs';
import { mdiPlus, mdiMinus, mdiAlert } from '@mdi/js';

export default {
  name: 'PersonFinder',
  components: {
    VueCropper,
    ImageContainer,
    RectangleButton,
  },
  directives: {
    resize: {
      inserted(el, binding) {
        const resizeObserver = new ResizeObserver((entries) => {
          for (let i = 0; i < entries.length; i += 1) {
            const entry = entries[i];

            if (entry.target.classList.contains('imageBoundingBox')) {
              binding.value();
            }
          }
        });

        resizeObserver.observe(el);
      },
    },
  },
  props: {
    isLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      boundingBoxStyles: [],
      imageContainers: [],
      detectFacesLoading: false,
      selectedFace: '',
      isEdit: false,
      cropperIndex: 0,
      defaultSimilarityScore: 0.95, // default value set on back-end
      minScoreThreshold: 0.8,
      maxScoreThreshold: 1.0,
      scoreIncrementStep: 0.001,
      scoreThreshold: 0.95, // default value set on back-end
      hasFaceChanged: false,
      containerStyle: {
        width: '100%',
        height: '45vh',
        display: 'flex',
        'justify-content': 'center',
        'border-radius': '4px',
        overflow: 'hidden',
      },
      icons: {
        mdiPlus,
        mdiMinus,
        mdiAlert,
      },
    };
  },
  computed: {
    ...mapGetters({
      personFinderImage: 'faceid/personFinderImage',
      personFinderImageName: 'faceid/personFinderImageName',
      personFinderFaces: 'faceid/personFinderFaces',
      personFinderSelectedFaceIndex: 'faceid/personFinderSelectedFaceIndex',
      personIdentificationScore: 'faceid/personIdentificationScore',
    }),
    isToShowScoreLowMessage() {
      return this.personIdentificationScore < this.defaultSimilarityScore;
    },
    avatarSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '60px';
        case 'sm': return '100px';
        case 'md': return '125px';
        case 'lg': return '125px';
        case 'xl': return '125px';
        default: return '60px';
      }
    },
    isInLoading() {
      return this.isLoading || this.detectFacesLoading;
    },
    hasFacesInImage() {
      if (this.personFinderFaces !== undefined) {
        return this.personFinderFaces.length > 0;
      }

      return true;
    },
  },
  watch: {
    personFinderImage() {
      this.deleteFaces();
    },
    selectedFace() {
      if (this.selectedFace && this.hasFaceChanged) {
        this.$emit('load-image', this.selectedFace);
      }
    },
    scoreThreshold() {
      if (this.scoreThreshold >= this.minScoreThreshold && (
        this.scoreThreshold <= this.maxScoreThreshold)) {
        this.setPersonFinderScoreThreshold(this.scoreThreshold);
      }
    },
  },
  created() {
    window.addEventListener('resize', this.getImageBoundingBox);
    this.imageContainers = document.getElementsByClassName('imageBoundingBox');
  },
  methods: {
    ...mapActions({
      removePersonFinderByImage: 'faceid/removePersonFinderByImage',
      selectPersonFinderFaceIndex: 'faceid/selectPersonFinderFaceIndex',
      setPersonFinderImage: 'faceid/setPersonFinderImage',
      setPersonFinderScoreThreshold: 'faceid/setPersonFinderScoreThreshold',
      deleteDetectedFaces: 'faceid/deleteDetectedFaces',
      detectFacesInTheChosenImage: 'faceid/detectFacesInTheChosenImage',
    }),
    handleResize() {
      this.getImageBoundingBox();
    },
    clearInputFiles(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    },
    onImageLoaded() {
      this.imageContainers = document.getElementsByClassName('imageBoundingBox');
      this.scoreThreshold = this.personIdentificationScore;

      if (this.hasFaceChanged) {
        this.detectFaces();
      } else {
        this.loadFaces();
      }
    },
    deleteFaces() {
      this.boundingBoxStyles = [];
      this.selectedFace = '';
      this.deleteDetectedFaces();
    },
    loadFaces() {
      const selectedIndex = this.personFinderSelectedFaceIndex;

      this.getImageBoundingBox();

      if (selectedIndex >= 0) {
        this.selectFace(selectedIndex);
      }
    },
    detectFaces() {
      this.detectFacesLoading = true;

      this.detectFacesInTheChosenImage().then(() => {
        this.detectFacesLoading = false;

        this.getImageBoundingBox();

        if (this.personFinderFaces.length === 1) {
          this.selectFace(0);
        }
      }).catch(() => {
        this.detectFacesLoading = false;
      });
    },
    selectFace(index) {
      if (index !== this.personFinderSelectedFaceIndex) this.hasFaceChanged = true;

      this.selectPersonFinderFaceIndex(index);

      const img = new Image();
      const boundingBox = this.personFinderFaces[index].bounding_box;

      img.onload = (face) => {
        const { width, height } = img;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const aspectRatio = boundingBox.width / boundingBox.height;

        canvas.width = 80 * aspectRatio;
        canvas.height = 80;

        const percentageWidth = boundingBox.width / width;
        const percentageHeight = boundingBox.height / height;

        const resizedWidth = width * percentageWidth;
        const resizedHeight = height * percentageHeight;

        const percentageX = boundingBox.top_left_x / width;
        const percentageY = boundingBox.top_left_y / height;

        const resizedXPos = width * percentageX;
        const resizedYPos = height * percentageY;

        const imageOffsetXPos = 0;
        const imageOffsetYPos = 0;

        const increaseFactor = 1.4;

        // Calcula o novo tamanho e posição ajustados
        const adjustedWidth = resizedWidth * increaseFactor;
        const adjustedHeight = resizedHeight * increaseFactor;
        const adjustedXPos = resizedXPos - (adjustedWidth - resizedWidth) / 2;
        const adjustedYPos = resizedYPos - (adjustedHeight - resizedHeight) / 2;

        ctx.drawImage(
          face.target,
          adjustedXPos,
          adjustedYPos,
          adjustedWidth,
          adjustedHeight,
          imageOffsetXPos,
          imageOffsetYPos,
          canvas.width,
          canvas.height,
        );

        const resizedImage = ctx.canvas.toDataURL(face.target, 'image/jpeg', 0);

        this.selectedFace = resizedImage;
      };

      img.src = this.personFinderImage;
    },
    getImageBoundingBox() {
      this.boundingBoxStyles = [];
      const imageFaces = this.personFinderFaces;

      if (imageFaces) {
        const img = new Image();

        img.onload = () => {
          const { height, width } = img;
          const imageContainerSelected = this.imageContainers[0];

          if (imageContainerSelected) {
            this.boundingBoxStyles = [];

            if (imageFaces && imageFaces.length > 0) {
              imageFaces.forEach((face) => {
                this.boundingBoxStyles.push(boundingBoxCalc({
                  height,
                  width,
                  currentImageInfo: face.bounding_box,
                  currentImageContainer: imageContainerSelected,
                }));
              });
            }
          }
        };

        img.src = this.personFinderImage;
      }
    },
    searchPeopleByImage($event) {
      const targetFile = $event.target.files[0];

      fileToBase64(targetFile).then((base64Image) => {
        const fileName = targetFile.name;

        this.setPersonFinderImage({ image: base64Image, name: fileName });
        this.hasFaceChanged = true;
      });
    },
    cropImage() {
      if (!this.isEdit) {
        this.isEdit = true;
        this.cropperIndex += 1;
        return;
      }

      const croppedCanvas = this.$refs.cropper.getCroppedCanvas();
      const croppedImage = croppedCanvas.toDataURL('image/png');

      // Update image extension to png
      const imageName = this.personFinderImageName.replace(/\.[^.]+$/, '.png');

      this.setPersonFinderImage({ image: croppedImage, name: imageName });
      this.hasFaceChanged = true;
      this.isEdit = false;
    },
  },
};
</script>

<style>
  .dropbox {
    outline: 2px dashed #8F93FF; /* the dash box */
    outline-offset: -10px;
    background: 'transparentBackground';
    padding: 4px;
    min-height: 200px; /* minimum height */
    width: 100%;
    position: relative;
    cursor: pointer;
  }
  .dropbox:hover {
    background: #EFEFEF;
  }
  .dropbox:active {
    background: #dbdbdb;
  }
  .input-file {
    opacity: 0; /* invisible but it's there! */
    height: 200px;
    width: 100%;
    position: absolute;
    cursor: pointer;
  }
</style>
