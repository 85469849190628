<template>
  <div
    class="d-flex justify-center align-center flex-wrap mt-auto"
    style="height: 64px; border-top: 1px solid; margin-bottom: 1px;"
    :style="{
      borderColor: $vuetify.theme.themes.light.border,
    }"
  >
    <div class="pa-2">
      <span
        class="grey--text input-text body-2 text-sm-body-1 neutral--text"
      >
        {{
          $t(
            'deconve.pageIteratorPeople',
            [
              numberOfTheFirstItemInPage,
              numberOfItemsInPage(totalData > 0 ? totalData : 0),
              totalData > 0 ? totalData : 0
            ]
          )
        }}
      </span>
    </div>

    <div class="pa-2">
      <v-btn
        icon
        @click="formerPage"
      >
        <v-icon
          size="22"
          color="neutral"
        >
          {{ icons.mdiChevronLeft }}
        </v-icon>
      </v-btn>
      <v-btn
        icon
        @click="nextPage"
      >
        <v-icon
          size="22"
          color="neutral"
        >
          {{ icons.mdiChevronRight }}
        </v-icon>
      </v-btn>
    </div>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          elevation="0"
          v-bind="attrs"
          :small="$vuetify.breakpoint.mobile"
          v-on="on"
        >
          <v-icon color="neutral">
            {{ icons.mdiDotsVertical }}
          </v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item>
          <v-list-item-title class="neutral--text body-2 text-sm-body-1">
            {{ $t('$vuetify.dataFooter.itemsPerPageText') }}
          </v-list-item-title>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                text
                color="neutral"
                class="ml-2 body-2 text-sm-body-1"
                v-bind="attrs"
                :small="$vuetify.breakpoint.mobile"
                v-on="on"
              >
                {{ itemsPerPage }}
                <v-icon :small="$vuetify.breakpoint.mobile">
                  {{ icons.mdiChevronDown }}
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="$emit('changeItensPerPage', number)"
              >
                <v-list-item-title
                  class="input-text body-2 text-sm-body-1 textBlack--text"
                >
                  {{ number }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            class="neutral--text body-2 text-sm-body-1"
          >
            {{ $t('deconve.pages') }}
          </v-list-item-title>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                text
                color="neutral"
                class="ml-2 body-2 text-sm-body-1"
                v-bind="attrs"
                :small="$vuetify.breakpoint.mobile"
                v-on="on"
              >
                {{ $t('deconve.pageIterator', [totalData > 0 ? page : 0, numberOfPages]) }}
                <v-icon :small="$vuetify.breakpoint.mobile">
                  {{ icons.mdiChevronDown }}
                </v-icon>
              </v-btn>
            </template>
            <v-list style="max-height: 300px; overflow-y: auto;">
              <v-list-item
                v-for="(number, index) in listOfPages"
                :key="index"
                @click="$emit('changePage', number)"
              >
                <v-list-item-title
                  class="input-text body-2 text-sm-body-1 textBlack--text"
                >
                  {{ number }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

import {
  mdiDotsVertical, mdiChevronDown, mdiChevronLeft, mdiChevronRight,
} from '@mdi/js';

export default {
  name: 'FooterDataIterator',
  props: {
    itemsPerPageArray: { type: Array, required: true },
    itemsPerPage: { type: Number, required: true },
    page: { type: Number, required: true },
    totalData: { type: Number, required: true },
  },
  data: () => ({
    icons: {
      mdiDotsVertical,
      mdiChevronDown,
      mdiChevronLeft,
      mdiChevronRight,
    },
  }),
  computed: {
    numberOfPages() {
      return Math.ceil(this.totalData / this.itemsPerPage);
    },
    listOfPages() {
      const list = [];

      for (let i = 1; i <= this.numberOfPages; i += 1) {
        list.push(i);
      }

      return list;
    },
    numberOfTheFirstItemInPage() {
      const firstItemNumber = this.page * this.itemsPerPage - (this.itemsPerPage - 1);

      return this.totalData > 0 ? firstItemNumber : 0;
    },
  },
  methods: {
    numberOfItemsInPage(numberOfItems) {
      const totalItemsbyPage = this.page * this.itemsPerPage;

      return totalItemsbyPage > numberOfItems
        ? numberOfItems : totalItemsbyPage;
    },
    nextPage() {
      if (this.page < this.numberOfPages) this.$emit('changePage', this.page + 1);
    },
    formerPage() {
      if (this.page > 1) this.$emit('changePage', this.page - 1);
    },
  },
};
</script>
