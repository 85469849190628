// Copyright (C) 2020 Deconve Technology. All rights reserved.

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import store from '../store';
import { blobToDataUrl } from '../utils/data';

// We use this method to help us manipulate the image content. When using <img> to fetch the image
// content from an url, the browsers disables the cors and does not allow us to access the image
// content because it is marked as tainted. See the following link for more details:
// https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image#security_and_tainted_canvases
export function downloadImage(url: string): Promise<string> {
  return new Promise((resolve) => {
    if (url) {
      const requestOptions: AxiosRequestConfig = {
        url,
        method: 'get',
        responseType: 'blob',
      };

      axios(requestOptions)
        .then((response) => blobToDataUrl(response as AxiosResponse))
        .then((image) => resolve(image))
        .catch(() => resolve(''));
    } else {
      resolve('');
    }
  });
}

export function getUserInfo(id: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const { authorizationToken } = store.getters;

    const authUrl = process.env.VUE_APP_DECONVE_AUTH_URL.replace('/v1', '/v2');

    const requestOptions: AxiosRequestConfig = {
      baseURL: authUrl,
      url: `info/${id}/`,
      method: 'GET',
      headers: {
        Authorization: authorizationToken,
      },
    };

    axios(requestOptions).then((response) => {
      const { data } = response;

      resolve(data);
    }).catch(() => {
      reject();
    });
  });
}
