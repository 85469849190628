var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"bottom":"","open-delay":"600","open-on-hover":"","disabled":_vm.isPersonEnabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var slotOn = ref.on;
var slotAttrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column",class:{ 'v-card--disabled': !_vm.isPersonEnabled },staticStyle:{"flex":"1","height":"fit-content","width":"100%"},style:({borderBottom: !_vm.isLoadingPerson && !_vm.isBeingMonitored ?
                 "8px solid red" : '',
               'pointer-events': _vm.isASharedPersonProfile ? 'none' : 'auto'}),attrs:{"min-width":"200","loading":_vm.isLoadingImage,"elevation":"0","outlined":""},on:{"click":_vm.seeProfile}},'v-card',slotAttrs,false),slotOn),[(_vm.isASharedPersonProfile && !_vm.isLoadingImage)?_c('div',{staticClass:"d-flex flex-column justify-center align-center ma-4",staticStyle:{"height":"350px"}},[_c('v-icon',{attrs:{"size":"48px","color":"neutral"}},[_vm._v(" "+_vm._s(_vm.icons.accountAlert)+" ")]),_c('div',{staticClass:"text-body-5 neutral--text"},[_vm._v(" "+_vm._s(_vm.$t('deconve.collaborativeDatabaseProfile'))+" ")])],1):_c('div',{staticClass:"d-flex flex-column ma-4",staticStyle:{"height":"350px"}},[_c('div',{staticClass:"d-flex flex mx-0",staticStyle:{"flex":"1","overflow":"hidden","border-radius":"4px"}},[_c('v-img',{staticClass:"d-flex flex-column rounded",attrs:{"width":"100%","aspect-ratio":"1/1","src":_vm.profileImage}})],1),_c('div',{staticClass:"d-flex flex-column mt-2",staticStyle:{"display":"flex","flex":"0","justify-content":"center"}},[_c('v-tooltip',{attrs:{"bottom":"","left":"","open-delay":"600","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
               var on = ref.on;
               var attrs = ref.attrs;
return [_c('strong',_vm._g(_vm._b({staticClass:"text-truncate subtitle-1 text-sm-h6 font-weight-bold textBlack--text"},'strong',attrs,false),on),[_vm._v(" "+_vm._s(_vm.profileName)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.profileName))])]),_c('div',{staticClass:"d-flex flex-wrap mb-4"},[_vm._l((_vm.previewTags),function(tag){return _c('tag',{key:tag.id,staticClass:"pa-1",attrs:{"small":"","tag-id":tag.id}})}),(_vm.tags.length > 2)?_c('div',{staticClass:"d-flex flex-column ml-2",staticStyle:{"flex":"1"}},[_c('v-spacer'),_c('square-button',{staticStyle:{"margin-top":"auto","z-index":"2"},attrs:{"icon-name":"mdi-tag-multiple-outline","outlined":"","color":"transparentBackground","icon-color":"neutral"}},[_c('v-card',{attrs:{"max-width":"300"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('deconve.tags'))+" ")]),_c('v-card-text',[_c('div',{staticClass:"d-flex flex-wrap mb-4"},_vm._l((_vm.tags),function(tag){return _c('tag',{key:tag.id,staticClass:"pa-1",attrs:{"tag-id":tag.id}})}),1)])],1)],1)],1):_vm._e()],2)],1),_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"neutral","size":_vm.$vuetify.breakpoint.mobile? 16: 20}},[_vm._v(" "+_vm._s(_vm.icons.calendarRange)+" ")]),(_vm.createdAt)?_c('span',{staticClass:"caption text-sm-body-2 neutral--text"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.createdAt,'DD/MM/YYYY'))+" ")]):_vm._e()],1),(!_vm.isLoadingPerson && !_vm.isBeingMonitored)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
               var on = ref.on;
               var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.information)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('deconve.person.noTrainedModels')))])]):_vm._e()],1)])])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('deconve.person.deactivationMessage')))])])}
var staticRenderFns = []

export { render, staticRenderFns }